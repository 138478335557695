import React, { useState, useEffect } from "react";
import "./Landing.css";
import JobSearchFeature from "../components/JobSearchFeature/JobSearchFeature";
import PricingCard from "../components/PricingCard/PricingCard";
import Analytics from "../components/Analytics/Analytics";
import { toast, ToastOptions, ToastContainer } from "react-toastify";
import Hotjar from "../components/Hotjar";

const PAYMENT_LINK_ONE_AND_DONE = "https://buy.stripe.com/3csg0Vez44139yw6or";

const JobSearchHeader: React.FC = () => {
	const [email, setEmail] = useState("");
	const [campaign, setCampaign] = useState("");

	const UNHAPPY_TOAST: ToastOptions = {
		position: "bottom-left",
		className: "toast-unhappy"
	};

	const HAPPY_TOAST: ToastOptions = {
		position: "bottom-left",
		className: "toast-happy"
	};

	function handleSubmit(e: React.FormEvent) {
		e.preventDefault();
		// Handle form submission logic here
		// console.log("Submitted email:", email);
		// console.log(`Submitted Campaign: [${campaign}]`);

		async function signUp() {
			const data = {
				email: email,
				campaignId: parseInt(campaign)
			};

			const API_BASE_URL =
				"https://resume-analyzer-api-gw-7xzqkadx.uc.gateway.dev";

			const response = await fetch(API_BASE_URL + "/contacts", {
				method: "POST",
				body: JSON.stringify(data)
			});
			if (response.ok) {
				// Success
				toast.success("Success! You're now on our mailing list!", HAPPY_TOAST);
			} else {
				if (response.status === 409) {
					// Already on mailing list
					toast.success(
						"You're already on our mailing list. Great!",
						HAPPY_TOAST
					);
					//throw new Error("Contact exists");
				} else {
					// Failure
					toast.warn("Something went wrong. Please try again!", UNHAPPY_TOAST);
					//throw new Error(`Response status: ${response.status}`);
				}
			}
		}

		signUp();
	}

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const campaign: string | null = searchParams.get("campaign");

		if (!(campaign === null)) {
			setCampaign(campaign);
		}
	}, []);

	return (
		<div className="job-search-landing">
			<div className="job-search-header">
				<div className="header-content">
					<h1 className="header-title">
						<span className="text-navy">Navigate Your </span>
						<span className="text-green">Job Search</span>
						<span className="text-navy"> with Confidence and Ease</span>
					</h1>
					<p className="header-subtitle">
						JobMap offers custom-generated cover letters and an intuitive job
						application tracker to help you manage your job search efficiently.
						Let Mappy guide you to your next career destination with ease.
					</p>
					<form onSubmit={handleSubmit} className="header-form">
						<input
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="Enter your email"
							className="email-input"
							required
						/>
						<Analytics
							measurementId="G-M5FQF9YL53"
							analyticsCat="BrevoSignup"
							analyticsLbl={campaign}
						>
							<button type="submit" className="submit-button">
								Sign up for updates here - JobMap launches in Q4 2024. 🚀
							</button>
						</Analytics>
					</form>
				</div>
			</div>
			<div className="job-search-feature">
				<h2 className="job-search-label">How it Works</h2>
				<JobSearchFeature
					title="Custom-Produced Cover Letters"
					description="JobMap leverages advanced artificial intelligence to generate personalized cover letters tailored to each job application. Say goodbye to the tedious task of crafting cover letters from scratch and focus on pursuing your dream job."
					imageSrc="cover-letter-image.png"
					isLeftHanded={true}
				/>

				<JobSearchFeature
					title="Comprehensive Job Application Tracker"
					description="Keep your job search organized with our intuitive job application tracker. Manually input your applications and easily drag them through categories such as 'First Interview' and 'Second Interview' to manage your progress efficiently."
					imageSrc="tracker-image.png"
					isLeftHanded={false}
				/>

				<JobSearchFeature
					title="Meet Mappy, Your Job Search Companion"
					description="Introducing Mappy, the friendly cartoon map who guides you on your job search journey. Mappy provides tips, motivation, and updates to keep your spirits high and your efforts focused."
					imageSrc="mappy-image.png"
					isLeftHanded={true}
				/>
			</div>

			<div className="job-search-pricing">
				<h2 className="job-search-label">Pricing</h2>
				<PricingCard
					title="One and Done"
					price={24.99}
					description="All of the tools you need to kickstart your job search."
					features={[
						"AI Assistants",
						"Job Tracker",
						"AI Resume Processing",
						"Manual Customization",
						"Email Alerts",
						"24x7 Support"
					]}
					vendorId="VID12345"
					paymentLinkUrl={PAYMENT_LINK_ONE_AND_DONE}
					campaignId={campaign ? parseInt(campaign) : undefined}
				/>
			</div>
			<ToastContainer></ToastContainer>
		</div>
	);
};

function Landing() {
	return (
		<>
			<Hotjar />
			<JobSearchHeader />
		</>
	);
}

export default Landing;
