import { useNavigate } from "react-router-dom";
import { ProfileBadge } from "../Profile";
import ContactFormModal from "../Contact/ContactFormModal";
import "./navbar.css";
import React from "react";

const Navbar = () => {
	const navigate = useNavigate();
	const debugEasterEgg: React.MouseEventHandler = (event) => {
		if (event.shiftKey) {
			navigate("/debug");
		}
	};
	return (
		<nav className="bg-[#4d9538]">
			<div className="max-w-7xl mx-auto">
				<div className="flex justify-between h-20">
					<div className="flex-shrink-0 flex items-center">
						<div className="flex items-center" onClick={debugEasterEgg}>
							<img
								className="h-16 w-auto"
								src={require("../static/mappy.png")}
								alt="Home"
							/>
							<span className="ml-2 text-xl font-semibold text-gray-800"></span>
						</div>
					</div>
					<p className="cursive-text">Jobmap</p>
					<div className="flex items-center">
						<div className="ml-1 relative">
							<ProfileBadge />
						</div>
						<div className="ml-8 relative">
							<ContactFormModal />
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
